@i /*mport url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Rock+Salt&display=swap');

*/
body {
    margin: 0;
    font-family: 'Fira Code', monospace;
    font-family: 'Fira Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    display: flex;
}

div {
    font-size: 1.25rem;
}

p {
    font-family: 'Fira Code', monospace;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.25rem;
    line-height: 1.5em;
    margin: 30px 0px;
}

.containerCustom {
    width: 80%;
    margin: 30px auto 0;
}

.test {
    height: 2000px;
}

h2.hero, h4.hero, h1.hero {
    color: #fff;
    font-family: 'Rock Salt', cursive;
    line-height: 2;
}

    h1.hero:before, h1.hero:after {
        height: 0px;
    }

h1 {
    overflow: hidden;
    text-align: center;
    color: #245FAF;
}

    h1:before,
    h1:after {
        background-color: #245FAF;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    h1:before {
        right: 0.5em;
        margin-left: -50%;
    }

    h1:after {
        left: 0.5em;
        margin-right: -50%;
    }

h2 {
    color: #245FAF;
    text-decoration: underline;
    font-size: 1.75rem;
}

.background {
    background-image: linear-gradient(to top right, rgba(36,95,175, .2) 20%, white 50%, rgba(36,95,175, .2) 90%);
    height: 100%;
}

.subscribe {
    margin: 50px auto 50px;
    text-align: center;
}

.width-50 {
    width: 50%;
}

.slick-list .slick-track {
    width: 80% !important;
}

.carousel {
    min-height: 300px;
}

.carousel-inner {
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;
    /*height: 500px;
    overflow-y: auto;*/
}

.carousel-item {
    text-align: center;
}

.chevron-icon {
    color: #245FAF;
    font-size: 1.5rem !important;
}

.page-footer {
    position: absolute;
    width: 100%;
    background-image: linear-gradient(to bottom right, rgba(36,95,175, .2) 20%, white 50%, rgba(36,95,175, .2) 90%);
    /*border-top: 1px solid #245FAF;*/
    padding: 10px 20px;
}

.col {
    margin-top: 10px;
}

.btn-submit {
    background-color: #245FAF;
    color: #fff;
}

    .btn-submit:hover {
        color: #EBF3F1;
        background-color: rgba(12,57,117, .75);
    }

#divSmallBoxes {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
}

.SmallBox {
    border: 1px solid transparent;
    color: white;
    overflow: hidden;
    position: absolute;
    right: 5px;
    top: 20px;
    width: 420px;
    z-index: 9999;
}

    .SmallBox .textoFull {
        float: left;
        padding-left: 20px;
        width: 93%;
    }

    .SmallBox span {
        display: block;
        font-size: 17px;
        font-weight: 300;
        letter-spacing: -1px;
        margin: 4px 0;
    }

    .SmallBox p {
        font-size: 13px;
        margin-top: 2px;
    }

.miniPic {
    bottom: 8px;
    position: absolute;
    right: 9px;
}

.miniIcono {
    font-size: 20px;
    height: 100%;
}

.navbar {
    position: absolute;
    z-index: 1;
}

.sunsetImg {
    width: 100%;
}

#wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}

    #wrapper td {
        vertical-align: middle;
        text-align: center;
    }

.page-footer-text {
    bottom: 0;
    vertical-align: bottom;
    position: absolute;
}

.gRJFTY {
    background: linear-gradient(to top left, rgba(0,0,0, 0.1) 10%, transparent 50%, rgba(255,255,255, .5) 80%) !important;
}






h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.3 !important;
}

.paypal {
    z-index: 1;
    position: absolute;
    width: 100%;
    text-align: right;
    padding: 25px;
}

.instagram-icon {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    box-shadow: 0px 3px 10px rgba(0,0,0,.25);
    border-radius: 25%;
    color: #fff;
}

.facebook-icon {
    color: #0C88EF;
    box-shadow: 0px 3px 10px rgba(0,0,0,.25);
    border-radius: 25%;
}

#amznCharityBannerInner {
    background-image: url(https://images-na.ssl-images-amazon.com/images/G/01/x-locale/paladin/charitycentral/banner-background-image._CB485922134_.png);
    width: 300px;
    height: 250px;
    position: relative
}

    #amznCharityBannerInner a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: #000;
        text-decoration: none
    }

.text {
    position: absolute;
    top: 20px;
    left: 15px;
    right: 15px;
    bottom: 100px
}

.support-wrapper {
    overflow: hidden;
    max-height: 86px
}

.support {
    font-family: Arial,sans;
    font-weight: 700;
    line-height: 28px;
    font-size: 25px;
    color: #333;
    text-align: center;
    margin: 0;
    padding: 0;
    background: 0 0
}

.when-shop {
    font-family: Arial,sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color: #333;
    text-align: center;
    margin: 0;
    padding: 0;
    background: 0 0
}

.donates {
    font-family: Arial,sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    color: #333;
    text-align: center;
    margin: 0;
    padding: 0;
    background: 0 0
}


.white-bg-slant {
    -webkit-transform: skewY(-45deg);
    -moz-transform: skewY(-45deg);
    -ms-transform: skewY(-45deg);
    -o-transform: skewY(-45deg);
    transform: skewY(-45deg);
    height: 400px;
    background: rgba(255,255,255,.6);
    width: 600px;
    position: absolute;
    z-index: 1;
    left: -190px;
}

.navbar-brand {
    z-index: 2;
}

.center-logo-mobile img {
    width: 250px;
}

@media screen and (max-width: 650px) {

    .navbar {
        width: 100%;
    }

    .center-logo-mobile img {
        width: 125px;
    }

    .white-bg-slant {
        left: -345px;
    }
}

.hero > div:first-child {
    background-attachment: unset !important;
    background-size: cover !important;
    background-position-y: unset !important;
}